import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import { hasNavigator, isSSR } from '@wix/thunderbolt-commons'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	SdkHandlersProvider,
	ViewModeSym,
	ViewMode,
	IMultilingual,
} from '@wix/thunderbolt-symbols'
import { IPopups, PopupsSymbol } from 'feature-popups'
import { IReporterApi, ReporterSymbol } from 'feature-reporter'
import { WindowWixCodeSdkHandlers } from '../types'
import { Animations, IAnimations } from 'feature-animations'
import { IWindowScrollAPI, WindowScrollApiSymbol } from 'feature-window-scroll'
import { MultilingualSymbol } from 'feature-multilingual'
import { TpaPopupSymbol, ITpaPopup, ITpaModal, TpaModalSymbol } from 'feature-tpa'

function setCurrentLanguage(languageCode: string): never {
	throw new Error(`language code "${languageCode}" is invalid`)
}

export const windowWixCodeSdkHandlers = withDependencies(
	[
		optional(Animations),
		BrowserWindowSymbol,
		ViewModeSym,
		TpaModalSymbol,
		TpaPopupSymbol,
		WindowScrollApiSymbol,
		optional(PopupsSymbol),
		optional(ReporterSymbol),
		optional(MultilingualSymbol),
	],
	(
		animations: IAnimations,
		window: BrowserWindow,
		viewMode: ViewMode,
		{ openModal }: ITpaModal,
		{ openPopup }: ITpaPopup,
		windowScrollApi: IWindowScrollAPI,
		popupsFeature?: IPopups,
		analyticFeature?: IReporterApi,
		multilingual?: IMultilingual
	): SdkHandlersProvider<WindowWixCodeSdkHandlers> => {
		return {
			getSdkHandlers: () => ({
				getBoundingRectHandler: () => {
					if (!window) {
						return null
					}
					return Promise.resolve({
						window: {
							height: window.innerHeight,
							width: window.innerWidth,
						},
						document: {
							height: document.documentElement.clientHeight,
							width: document.documentElement.clientWidth,
						},
						scroll: {
							x: window.scrollX,
							y: window.scrollY,
						},
					})
				},
				setCurrentLanguage: multilingual?.setCurrentLanguage || setCurrentLanguage,
				async scrollToComponent(compId: string, callback: Function) {
					if (!process.env.browser) {
						return
					}
					await windowScrollApi.scrollToComponent(compId)
					callback()
				},
				async scrollToHandler(x, y, shouldAnimate) {
					if (isSSR(window)) {
						return
					}
					if (!shouldAnimate) {
						window.scrollTo(x, y)
					}
					return windowScrollApi.animatedScrollTo(y)
				},
				async scrollByHandler(x, y) {
					if (isSSR(window)) {
						return
					}
					window.scrollBy(x, y)
					return new Promise((resolve) => {
						window.requestAnimationFrame(() => {
							resolve()
						})
					})
				},
				getCurrentGeolocation() {
					return new Promise((resolve, reject) => {
						if (hasNavigator(window)) {
							navigator.geolocation.getCurrentPosition(resolve, ({ message, code }) => {
								reject({ message, code })
							})
						}
					})
				},
				openModal,
				openLightbox(lightboxPageId, lightboxName, closeHandler) {
					return popupsFeature
						? popupsFeature.openPopupPage(lightboxPageId, closeHandler)
						: Promise.reject(`There is no lightbox with the title "${lightboxName}".`)
				},
				closeLightbox() {
					if (popupsFeature) {
						popupsFeature.closePopupPage()
					}
				},
				openTpaPopup: openPopup,
				trackEvent(eventName: string, params = {}, options = {}) {
					const event = { eventName, params, options }
					analyticFeature && analyticFeature.trackEvent(event)
				},
				postMessageHandler(
					message: any,
					target: string = 'parent',
					targetOrigin: string = '*',
					transfer?: Array<Transferable>
				) {
					if (!window) {
						return
					}

					if (target !== 'parent') {
						return
					}

					window.parent.postMessage(message, targetOrigin, transfer)
				},
			}),
		}
	}
)
