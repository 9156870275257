import { optional, withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, DynamicPagesSymbol, CurrentRouteInfoSymbol } from '@wix/thunderbolt-symbols'
import { DynamicPagesAPI, ICurrentRouteInfo, FetchParams } from 'feature-router'

export const siteSdkProvider = withDependencies(
	[optional(DynamicPagesSymbol), CurrentRouteInfoSymbol],
	(dynamicPagesAPI: DynamicPagesAPI, currentRouteInfo: ICurrentRouteInfo): SdkHandlersProvider => ({
		getSdkHandlers: () => {
			return {
				getSitemapFetchParams: (routerPrefix: string): FetchParams | null => {
					if (!dynamicPagesAPI) {
						return null
					}

					const routeInfo = currentRouteInfo.getCurrentRouteInfo()
					if (!routeInfo) {
						return null
					}

					return dynamicPagesAPI.getSitemapFetchParams(routerPrefix, routeInfo)
				},
			}
		},
	})
)
